<template>
  <header>
    <div class="phoneHeader">
     <font-awesome-icon icon="fa-solid fa-phone" />
      Telefon: <a href='tel:+48605690502'>605-690-502</a>
      <span><font-awesome-icon icon="fa-solid fa-house" /> Tarnowskie Góry</span>
    </div>
  </header>
    <swiper
      :slidesPerView="1"
      :modules="modules"
      :autoplay="{ delay: 3000 }"
      :effect="'slide'"
      :speed="1500"
      :loop="true"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide>
        <div class="sliderImageBox">
          <img class="sliderImageBg dom1" src="./assets/dom1.jpg" alt=""/>
          <div class="overlay">
            <div class="imgOverlay"><img src="./assets/logo1.png" alt="" /></div>
              <div class="textOverlay">Świadectwo energetyczne dla Twojego budynku</div>
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="sliderImageBox">
          <img class="sliderImageBg dom2" src="./assets/dom2.jpg" alt=""/>
          <div class="overlay">
              <div class="imgOverlay">
                  <img src="./assets/logo1.png" alt="" />
              </div>
              <div class="textOverlay">Świadectwo energetyczne dla Twojego mieszkania</div>
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="sliderImageBox">
          <img class="sliderImageBg dom3" src="./assets/dom3.jpg" alt=""/>
          <div class="overlay">
              <div class="imgOverlay">
                  <img src="./assets/logo1.png" alt="" />
              </div>
              <div class="textOverlay">Audyt  energetyczny do "Czystego powietrza"</div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  <router-view/>
  <footer>
    Copyright © 2023 tgolder.pl Wszelkie prawa zastrzeżone.
  </footer>
  <div v-if="cookie === false" id="cookies-message">
    Strona internetowa wykorzystuje mechanizm plików cookies. Więcej informacji można znaleźć w <a href="cookie">informacjach o plikach cookie</a>.
    <button class="cookies-accept"  @click="acceptCookie">Rozumiem</button>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, EffectCube } from 'swiper/modules';

// Import Swiper styles
import 'swiper/swiper-bundle.css';
import VueCookies from 'vue-cookies';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      modules: [Autoplay, EffectCube],
      onSwiper,
      onSlideChange,
    };
  },
  data() {
    return {
      cookie: false,
    };
  },
  methods: {
    acceptCookie: function () {
      VueCookies.set('cookie', 'accept', '30d');
      this.cookie = true;
    },
  },
  mounted() {
    if (VueCookies.isKey('cookie')) {
      this.cookie = true;
    }
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
  :root {
    --color-header: #314850;
  }

  *,
  body {
    font-family: 'Poppins', sans-serif;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    scroll-behavior: smooth !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    background-color: var(--color-header);
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  header {
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 60px;
    background-color: var(--color-header);
    z-index: 1000;

    .phoneHeader {
      font-size: 20px;

      span {
        margin-left: 35px;
      }
    }
  }

  .swiper {
    margin-bottom: 20px;
  }
  .sliderImageBox {
      height: 100vh;
      width: 100%;
      overflow:hidden;

      .dom1 {
          object-position: -350px -190px;

          @media (min-width: 1500px) {
            object-position: 0 0;
          }
        }

      .dom2 {
        object-position: -400px -237px;
        @media (min-width: 1500px) {
          object-position: 0 0;
        }
      }

      .dom3 {
        object-position: -977px -1442px;
        @media (min-width: 1500px) {
          object-position: -680px -1040px;
        }
      }

      .sliderImageBg {
        object-fit: cover;
      }

    }

  .overlay {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3); /* Black see-through */
    width: 100%;
    height:100%;
    transition: .5s ease;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    text-align: center;
    padding: 0px 20px;
    text-shadow: black 4px 3px 5px;
    line-height: 55px;

    @media (min-width: 768px) {
        line-height: normal;
        justify-content:flex-start;
      }

      .imgOverlay {
        @media (min-width: 768px) {
          position: absolute;
          top: 0;
          right: 0;
          margin-top: 75px;
          margin-right: 25px;
        }
      }

      .textOverlay {
        @media (min-width: 768px) {
         margin-top: 250px;
        }
      }
    }

    footer {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      height: 60px;
      font-size: 15px;
      text-align: center;
    }

    #cookies-message {
      padding: 8px;
      font: 12px Arial;
      line-height: 22px;
      text-align: center;
      position: fixed;
      bottom: 0px;
      background-color: rgba(0, 0, 0, 0.9);
      width: 100%;
      z-index: 9999;
      color: #fff;

      .cookies-accept {
        display: inline-block;
        margin-left: 20px;
        color: #333;
        text-align: center;
        cursor: pointer;
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
        background-color: #0663b4;
        border-style: solid;
        border-width: 1px;
        border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
        padding: 2px 10px;
        text-decoration: none;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
      }
    }

</style>
