<template>
  <About />
</template>

<script>
// @ is an alias to /src
import About from '@/components/About.vue';

export default {
  name: 'HomeView',
  components: {
    About,
  },
};
</script>
<style lang="scss" >
.container {
  max-width: 1140px;
  margin: auto;
  padding: 0px 15px;
}
</style>
