import { createApp } from 'vue';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

import App from './App.vue';
import router from './router';

library.add(fas, fab, far);

createApp(App).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app', AOS.init({ duration: 2100 }), Fancybox.bind('[data-fancybox]'));
