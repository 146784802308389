<template>
  <div class="container">
    <div class="aboutTitle">O mnie</div>
    <div class="row" data-aos="fade-up">
      <div class="column">
        <div class="aboutImage">
        </div>
      </div>
      <div class="column">
        <div class="aboutText">
          <div class="aboutMe">Mgr. inż. Tomasz Golder <br /><span>nr wpisu do rejestru 7641</span></div>
          Wykonuję Świadectwa charakterystyki energetycznej niezbędne do:
          <ul>
            <li><font-awesome-icon icon="check" />złożenia zawiadomienia o zakończeniu budowy,</li>
            <li><font-awesome-icon icon="check" />sporządzenia aktu notarialnego zbycia prawa własności budynku, lokalu, mieszkania lub spółdzielczego własnościowego prawa do lokalu (art. 11 ust. 1 pkt 1 ustawy o ChEB),</li>
            <li><font-awesome-icon icon="check" />umowy najmu budynku, lokalu, mieszkania (art. 11 ust. 1 pkt 2 ustawy o ChEB).</li>
          </ul>
          Wykonuję audyty energetyczne niezbędne do uzyskania dofinansowania w ramach programu "Czyste powietrze".
        </div>
      </div>
    </div>
    <div class="phoneEmailInfo">
      <span><font-awesome-icon icon="fa-solid fa-phone" /> Telefon: <a href='tel:+48605690502'>605-690-502</a></span> <span><font-awesome-icon icon="fa-solid fa-envelope" /> Adres e-mail: <a href='mailto:"tomgol4272@gmail.com"'> tomgol4272@gmail.com</a></span><span><font-awesome-icon icon="fa-solid fa-list" /> <a href="https://rejestrcheb.mrit.gov.pl/rejestr-uprawnionych" target="_blank">Rejestr osób uprawnionych</a></span>
    </div>
    <div class="row" style="margin-top: 40px;" data-aos="fade-up">
      <div class="column">
        <div class="aboutText">
          <ul>
            <li><font-awesome-icon icon="check" />świadectwo szacuje ile energii potrzebne jest na ogrzanie budynku, podgrzanie ciepłej wody użytkowej i w przypadku budynków niemieszkalnych na oświetlenie,</li>
            <li><font-awesome-icon icon="check" />porównanie parametrów prezentowanych w świadectwie pozwala porównać budynki pod względem energochłonności i wielkości emisji CO2 do atmosfery,</li>
            <li><font-awesome-icon icon="check" />audyt energetyczny proponuje prace termomodernizacyjne i szacuje oszczędności w zużyciu energii uzyskane po ich wykonaniu.</li>
          </ul>
        </div>
      </div>
      <div class="column">
        <div class="houseImage">
        </div>
      </div>
    </div>
    <div class="projectInfo">
      <p>Potrzebujesz świadectwo/audyt energetyczny, przyjadę, pomierzę, skontaktuję się z Zarządcą budynku, zbiorę potrzebne informacje, dostarczę gotowe świadectwo.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .aboutTitle {
    font-size: 25px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 3px;

    &:after {
      content: '';
      display: block;
      margin: 5px 0px 20px;
      border-bottom: 1px solid #fff;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .column {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
      justify-content: center;
      align-items: center;
    }
  }
  .aboutText {
    color: #fff;
    line-height: 30px;
    margin-top: 30px;

    .aboutMe {
      font-size: 30px;
      text-align: center;
      margin-bottom: 20px;

      span {
        font-size: 18px;
      }
    }

    ul {
      list-style-type: none;

      li {
        list-style-type: none;
          display: flex;
          align-items: flex-start;
          margin-bottom: 20px;
      }

      svg {
        padding: 5px 10px 0px 0px;
      }
    }
  }
  .aboutImage {
    width: 300px;
    height: 300px;
    background-image: url('../assets/me.jpg');
    background-size: cover;
    background-position: center center;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 3px solid #fff;

      @media (min-width: 768px) {
        width: 400px;
        height: 400px;
      }
  }

  .houseImage {
    width: 300px;
    height: 300px;
    background-image: url('../assets/domek.jpg');
    background-size: cover;
    background-position: center center;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 3px solid #fff;

      @media (min-width: 768px) {
        width: 400px;
        height: 400px;
      }
  }

  .projectInfo {
    margin-top: 40px;
    padding: 20px 0px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;

    p {
      text-align: center;
      font-size: 25px;
      color: #fff;
    }
  }

  .phoneEmailInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #fff;
    margin: 40px 0px;
    padding: 20px 0px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    font-size: 16px;
    line-height: 45px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }

    span {
      margin-right: 25px;
    }
  }
</style>
